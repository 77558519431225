const mutations = {
  SETUSER(state, user) {
    state.user = user
  },
  SETTOKEN(state, token) {
    state.token = token
  },
  SETSTUDENT(state, obj) {
    state.student = obj
  },
  SETHEADIMAGE(state, obj) {
    state.headImage = obj
  },
  SETOPERATIONAL(state, obj) {
    state.operational = obj
  },
  SETSTUDENARR(state, arr) {
    state.studentarr = arr
  },
  SETBALANCEMPPTYPE(state, val) {
    state.balanceMppType = Number(val)
  },
  SETCARDTYPE(state, val) {
    state.cardType = val
  },
  SETREQUIREDCERTIFICATION(state, val) {
    state.requiredCertification = val
  }
}
export default mutations