import jWeixin from 'weixin-js-sdk'
import {
  getJsapi,
  getShare
} from "@/api/home/index";
import {Toast,Dialog} from 'vant'
export default {
  /* 判断是否在微信中 */
  isWechat: function () {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/micromessenger/i) == 'micromessenger') {

      return true;
    } else {
      //以下是我项目中所需要的操作其他，可以自定义
      // uni.showModal({
      // 	title: '提示',
      // 	content: '请在微信浏览器中打开',
      // 	showCancel: false,
      // 	success: function(res) {
      // 		if (res.confirm) {
      // 			// console.log('用户点击确定');
      // 		} else if (res.cancel) {
      // 			// console.log('用户点击取消');
      // 		}
      // 	}
      // });
      Toast.fail('请在微信浏览器中打开');
      return false;
    }
  },
  /* 获取sdk初始化配置 */
  initJssdk: function (callback) {
    //获取当前url然后传递给后台获取授权和签名信息 
    // 此处url涉及到一个坑点、后面会说到
    // var url = /(Android)/i.test(navigator.userAgent) ? window.location.href.split('#')[0] : uni.getStorageSync('scanUrl')
    var url = window.location.href.split('?')[0]
    console.log(url, 'url')
    getJsapi({
      url: url
    }).then(res => {
      const jsapi = res.data.data
      console.log('jsapi:', jsapi);
      jWeixin.config({
        debug: false,
        appId: jsapi.appId,
        timestamp: jsapi.timestamp,
        nonceStr: jsapi.nonceStr,
        signature: jsapi.signature,
        jsApiList: [ //这里是需要用到的接口名称  
          'checkJsApi', //判断当前客户端版本是否支持指定JS接口  
          'updateAppMessageShareData', //分享朋友
          'updateTimelineShareData', //分享朋友圈  
          'getLocation', //获取位置  
          'openLocation', //打开位置  
          'scanQRCode', //扫一扫接口  
          'chooseWXPay', //微信支付  
          'chooseImage', //拍照或从手机相册中选图接口  
          'previewImage', //预览图片接口  
          'uploadImage' //上传图片  
        ]
      });
      if (callback) {
        callback(jsapi);
      }
      jWeixin.error(function (res) {
        // jWeixin 对象错误的处理
        console.error('jWeixin 错误:', res);
      });
    })
    // jWeixin.ready(function(data) {})

  },
  //微信扫码
  scanQRCode: function (callback) {
    if (!this.isWechat()) {
      return;
    }
    this.initJssdk( function(res) {
      console.log(res, '扫码res')
      jWeixin.ready( function () {
        setTimeout(() => {
          jWeixin.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              console.log(res, '成功res')
              // Dialog.alert({
              //   title: 'scanqr成功',
              //   message:JSON.stringify(res),
              // }).then(() => {
              //   // on close
              // });
              callback.success(res);
            },
            fail: function (res) {
              Toast.fail('请在刷新后重试');
              console.log(res, '失败res')
              Dialog.alert({
                title: '扫码失败',
                message:JSON.stringify(res),
              }).then(() => {
                // on close
              });
              callback.fail(res);
            },
          });
        }, 300)
      });

    });
  },
  //在需要定位页面调用  
  getlocation: function (callback) {
    if (!this.isWechat()) {
      //console.log('不是微信客户端')  
      return;
    }
    this.initJssdk(function () {
      jWeixin.ready(function () {
        jWeixin.getLocation({
          type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'  
          success: function (res) {
            console.log('微信定位成功', res);
            callback(res)
          },
          fail: function (res) {
            console.log('微信定位失败', res)
          },
        });
      });
    });
  },
  //打开位置  
  openlocation: function (data) {
    if (!this.isWechat()) {
      //console.log('不是微信客户端')  
      return;
    }
    this.initJssdk(function () {
      jWeixin.ready(function () {
        jWeixin.openLocation({ //根据传入的坐标打开地图  
          latitude: data.latitude,
          longitude: data.longitude
        });
      });
    });
  },
  //选择图片  
  chooseImage: function (callback) {
    if (!this.isWechat()) {
      //console.log('不是微信客户端')  
      return;
    }
    //console.log(data);  
    this.initJssdk(function () {
      jWeixin.ready(function () {
        jWeixin.chooseImage({
          count: 1,
          sizeType: ['compressed'],
          sourceType: ['album'],
          success: function (rs) {
            callback(rs)
          }
        })
      });
    });
  },
  //微信支付  
  wxpay: function (data, callback) {
    console.log(data, '微信支付data')
    if (!this.isWechat()) {
      //console.log('不是微信客户端')
      return;
    }
    this.initJssdk(function () {
      jWeixin.ready(function () {
        jWeixin.chooseWXPay({
          timestamp: data
            .timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符  
          nonceStr: data.nonceStr, // 支付签名随机串，不长于 32 位  
          package: data
            .package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）  
          signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'  
          paySign: data.paysign, // 支付签名  
          success: function (res) {
            // console.log(res);  
            callback(res)
          },
          fail: function (res) {
            callback(res)
          },
        });
      });
    });
  },

  //自定义分享  这里我统一调用了分享到朋友和朋友圈，可以自行定义
  share: function (callback) {
    if (!this.isWechat()) {
      //console.log('不是微信客户端')  
      return;
    }
    this.initJssdk(function () {
      jWeixin.ready(function () {

        //我的分享配置由后台返回，可以自定义
        getShare().then(res => {
          const {
            shareInfo
          } = res.data;
          jWeixin.updateAppMessageShareData({ //分享给朋友
            title: shareInfo.title,
            desc: shareInfo.description,
            imgUrl: shareInfo.image,
            link: shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            success: function () {
              // 用户确认分享后执行的回调函数
              callback(res);
            }
          });
          jWeixin.updateTimelineShareData({ //分享到朋友圈
            title: shareInfo.title,
            desc: shareInfo.description,
            imgUrl: shareInfo.image,
            link: shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            success: function () {
              // 用户确认分享后执行的回调函数
              callback(res);
            }
          });
        });
      });
    });
  }
}