import request, { get } from '@/utils/request'
import store from '../../store'
export function getFlowDetail(data) {
  return get('/wx/jsScalePkg/getFlowDetail', data);
}
export function getWXuser(data) {
  return get('/customermgr/cuser/getWXuser', data);
}
export function getPageAccountDetail(data) {
  return request({
    url: '/wx/account/pageAccountDetail',
    method: 'get',
    params: data
  })
}
export function getRechargeList(data) {
  return request({
    url: '/wx/jsScalePkg/getRechargeList',
    method: 'post',
    params: data
  })
}
export function getPackages(data) {
  return request({
    url: '/wx/jsScalePkg/getPackages',
    method: 'post',
    params: data
  })
}
export function saveCardMoneyAuto(data) {
  return request({
    url: '/h5/card/saveCardMoneyAuto',
    method: 'post',
    data
  })
}
export function getCardMoneyPromotion(data) {
  return request({
    url: '/h5/coupons/getCardMoneyPromotion',
    method: 'get',
    params: data
  })
}
export function saveCardMoneyCoupon(data) {
  return request({
    url: '/h5/coupons/saveCardMoneyCoupon',
    method: 'post',
    data
  })
}
export function getCouponByStatus(data) {
  return request({
    url: '/h5/coupons/getCouponByStatus',
    method: 'get',
    params: data
  })
}
export function moneyPromotionPage(data) {
  return request({
    url: '/wx/account/moneyPromotionPage',
    method: 'get',
    params: data
  })
}
export function prePayGroup(data) {
  return request({
    url: `/wx/${store.state.cardType == 1 ? 'jsScaleGroup/null/prePay' : 'jsScalePkg/pay'}`,
    method: 'post',
    params: data
  })
}
export function payBalance(data) {
  return request({
    url: '/wx/jsScalePkg/payBalance',
    method: 'post',
    params: data
  })
}
export function tdPayBalance(data) {
  return request({
    url: '/td/card/payBalance',
    method: 'post',
    params: data
  })
}
export function diffVerifiedFlag(data) {
  return request({
    url: '/h5/verified/diffVerifiedFlag',
    method: 'get',
    params: data
  })
}
export function packageActiveTimeAndExpireTime(data) {
  return request({
    url: '/card/recharge/packageActiveTimeAndExpireTime',
    method: 'post',
    params: data
  })
}
export function balanceRecPay(data) {
  return request({
    url: '/wx/jsScalePkg/balanceRecPay',
    method: 'post',
    params: data
  })
}
export function getWhiteListByIccid(data) {
  return request({
    url: '/whiteList/getWhiteListByIccid',
    method: 'post',
    params: data
  })
}
export function getVoiceCardWhitelistOperateList(data) {
  return request({
    url: '/whiteList/getVoiceCardWhitelistOperateList',
    method: 'post',
    params: data
  })
}
export function addMobileIccidToWhiteList(data) {
  return request({
    url: `/whiteList/${store.state.operational.operator == 2 ? 'addIccidToWhiteList' : 'addMobileIccidToWhiteList'}`,
    method: 'post',
    data
  })
}
export function deleteIccidFromWhiteList(data) {//deleteEcMobileIccidFromWhiteList
  return request({
    url: `/whiteList/${store.state.operational.operator == 2 ? 'deleteIccidFromWhiteList' : 'deleteEcMobileIccidFromWhiteList'}`,
    method: 'post',
    data
  })
}
export function toCardRefreshOrActivate(data) {
  return request({
    url: '/wx/scalePkg/toCardRefreshOrActivate',
    method: 'get',
    params: data
  })
}
export function checkorderCount(data) {
  return request({
    url: '/wx/jsScalePkg/balCheckOrderCount',
    method: 'get',
    params: data
  })
}
export function checkRechargeType(data) {
  return request({
    url: '/wx/card/checkRechargeType',
    method: 'get',
    params: data
  })
}
export function getPackageList(data) {
  return request({
    url: '/wx/jsScalePkg/getPackageList',
    method: 'post',
    params: data
  })
}
export function getUserCardDomain(data) {
  return request({
    url: '/wx/jsScalePkg/getUserCardDomain',
    method: 'get',
    params: data
  })
}
export function selectCardMoneyAuto(data) {
  return request({
    url: '/h5/card/selectCardMoneyAuto',
    method: 'get',
    params: data
  })
}

//客户公众号
export function packageRecharge(useId, data) {
  return request({
    url: `/idc/mp/${useId}/packageRecharge`,
    method: 'get',
    params: data
  })
}