/* eslint-disable */
const Utils = {}
import { Toast } from 'vant';

var authLocalGlobal = 1;        //1是本地(本地)，2是外部链接（官方）,3不要实名
var verifyPhoneGlobal = 2;      //验证手机号，1需要，2不需要，3通过
/** 参数说明：
 * 根据长度截取先使用字符串，超长部分追加…
 * str 对象字符串
 * len 目标字节长度
 * 返回值： 处理结果字符串
 */
Utils.cutString = (str, len) => {
  if (str.length * 2 <= len) {
    return str
  }
  let strlen = 0
  let s = ''
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-line
    s += str.charAt(i)
    if (str.charCodeAt(i) > 128) {
      strlen += 2
      if (strlen >= len) {
        return `${s.substring(0, s.length - 1)}...`
      }
    } else {
      strlen += 1
      if (strlen >= len) {
        return `${s.substring(0, s.length - 2)}...`
      }
    }
  }
  return s
}

/**
 * 简单数组的交集
 * @param {Array} a
 * @param {Array} b
 */
Utils.getIntersect = (a, b) => {
  if (a.constructor === Array && b.constructor === Array) {
    const set1 = new Set(a)
    const set2 = new Set(b)
    return Array.from(new Set([...set1].filter(x => set2.has(x))))
  }
  return null
}

/**
 * 返回 n 位的随机字符串
 * @param {Number} n
 */
Utils.getRandomStr = (n = 6) => {
  let str = ''
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890'
  for (let i = 0; i < n; i += 1) {
    str += chars.charAt(Math.floor(Math.random() * 62))
  }
  return str
}


Utils.getTypeOf = (obj) => {
  const { toString } = Object.prototype
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object',
    '[object Symbol]': 'symbol',
  }
  return map[toString.call(obj)]
}

Utils.insertItem = (item, arr) => {
  const { order } = item
  if (typeof arr[order] !== 'number') {
    arr[order] = item
    return
  }
  let moveBegin
  let moveEnd
  let pos
  let i = order + 1

  while (arr[i]) {
    if (arr[i].order > order) {
      if (!moveBegin) {
        moveBegin = i
        pos = i
      }
    }
    i += 1
  }

  if (moveBegin) {
    moveEnd = i
  } else {
    pos = i
  }

  if (!moveEnd) {
    arr[pos] = item
    return
  }

  // 需要移动
  for (let i = moveEnd; i >= moveBegin; i -= 1) {
    arr[i + 1] = arr[i]
  }
  arr[pos] = item
}

/**
 * 根据数组的 order 字段排序
 * @param {Array} source
 */
Utils.sortByOrder = (source = []) => {
  if (!Array.isArray(source)) {
    console.error('sortByOrder 传入参数不符合要求, 应为数组', source)
    return source
  }
  const tmp = []
  let target = []

  // 将带排序的子项添加进临时数组 tmp
  for (let i = 0; i < source.length; i += 1) {
    if (typeof source[i].order !== 'number') {
      continue
    }
    let { order } = source[i]
    // 支持设置倒数顺序
    if (order < 0) {
      order = source.length + order
      if (order < 0) {
        order = 0
      }
    }

    // 确保整数
    source[i].order = Math.floor(order)

    // 插入临时数组
    insertItem(source[i], tmp)
  }

  // 合并临时数组和原数组
  for (let i = 0, j = 0; i < source.length; i += 1) {
    if (typeof source[i].order === 'number') {
      continue
    }
    // 找需要填的坑
    while (tmp[j]) {
      j += 1
    }
    tmp[j] = source[i]
  }
  // 筛除空隙
  target = tmp.filter(item => !!item)
  return target
}


/**
 * 深度遍历，深拷贝
 * @param {*} data
 */
Utils.deepClone = data => cloneDeep(data)


let cached;
/**
 * 获取窗口滚动条大小, From: https://github.com/react-component/util/blob/master/src/getScrollBarSize.js
 * @param {boolean} fresh 强制重新计算
 * @returns {number}
 */
Utils.getScrollBarSize = (fresh) => {
  if (fresh || cached === undefined) {
    const inner = document.createElement('div');
    inner.style.width = '100%';
    inner.style.height = '200px';

    const outer = document.createElement('div');
    const outerStyle = outer.style;

    outerStyle.position = 'absolute';
    outerStyle.top = 0;
    outerStyle.left = 0;
    outerStyle.pointerEvents = 'none';
    outerStyle.visibility = 'hidden';
    outerStyle.width = '200px';
    outerStyle.height = '150px';
    outerStyle.overflow = 'hidden';

    outer.appendChild(inner);

    document.body.appendChild(outer);

    const widthContained = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    let widthScroll = inner.offsetWidth;

    if (widthContained === widthScroll) {
      widthScroll = outer.clientWidth;
    }

    document.body.removeChild(outer);

    cached = widthContained - widthScroll;
  }
  return cached;
}

//特殊字符处理
Utils.htmlEscape = (text) => {
  return text.replace(/[<>"&]/g, function (match, pos, originalText) {
    switch (match) {
      case "<":
        return "&lt;";
      case ">":
        return "&gt;";
      case "&":
        return "&amp;";
      case "\"":
        return "&quot;";
    }
  });
}

//获取URL参数
Utils.urlQuery = (key) => {
  var reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)")
  var val = window.location.search.substr(1).match(reg)
  if (val != null) {
    return unescape(val[2])
  } else {
    return null
  }
}

// 替换参数
Utils.replaceUrlParamVal = (url, paramName, replaceWith) => {
  var re = eval('/(' + paramName + '=)([^&]*)/gi');
  var nUrl = url.replace(re, paramName + '=' + replaceWith);
  return nUrl;
}

/**
 * Created by meilulan on 2017/7/28.
 */
/** 解决js浮点运算bug**/

// 两个浮点数求和
Utils.accAdd = (num1, num2) => {
  var r1, r2, m
  try {
    r1 = num1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = num2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2))
  return Math.round(num1 * m + num2 * m) / m
}

// 两个浮点数相减
Utils.accSub = (num1, num2) => {
  var r1, r2, m
  try {
    r1 = num1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = num2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2))
  var n = (r1 >= r2) ? r1 : r2
  return (Math.round(num1 * m - num2 * m) / m).toFixed(n)
}

// 两数相除
Utils.accDiv = (num1, num2) => {
  var t1, t2, r1, r2
  try {
    t1 = num1.toString().split('.')[1].length
  } catch (e) {
    t1 = 0
  }
  try {
    t2 = num2.toString().split('.')[1].length
  } catch (e) {
    t2 = 0
  }
  r1 = Number((num1 + '').replace('.', ''))
  r2 = Number(num2.toString().replace('.', ''))
  return ((r1 / r2) * Math.pow(10, t2 - t1)).toFixed(2)
}

// 两数相乘
Utils.accMul = (num1, num2) => {
  var m = 0; var s1 = num1.toString(); var s2 = num2.toString()
  try {
    m += s1.split('.')[1].length
  } catch (e) {
    // console.log(e)
  }

  try {
    m += s2.split('.')[1].length
  } catch (e) {
    // console.log(e)
  }
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}

/**
 * @description 绑定事件 on(element, event, handler)
 */
Utils.on = (function () {
  if (document.addEventListener) {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.addEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event && handler) {
        element.attachEvent('on' + event, handler)
      }
    }
  }
})()

/**
 * @description 解绑事件 off(element, event, handler)
 */
Utils.off = (function () {
  if (document.removeEventListener) {
    return function (element, event, handler) {
      if (element && event) {
        element.removeEventListener(event, handler, false)
      }
    }
  } else {
    return function (element, event, handler) {
      if (element && event) {
        element.detachEvent('on' + event, handler)
      }
    }
  }
})()

/**
     * 获取几天之后的日期
     * @param {*} AddDayCount
     */
Utils.GetDateStr = (AddDayCount) => {
  var dd = new Date()
  dd.setDate(dd.getDate() + AddDayCount)// 获取AddDayCount天后的日期
  var y = dd.getFullYear()
  var m = (dd.getMonth() + 1) < 10 ? '0' + (dd.getMonth() + 1) : (dd.getMonth() + 1)// 获取当前月份的日期，不足10补0
  var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()// 获取当前几号，不足10补0
  return y + '-' + m + '-' + d
}

/**
 * 获取时间差
 * @param {*} startTime 
 * @param {*} endTime 
 */
Utils.intervalTime = (startTime, endTime) => {
  var diff = endTime - startTime//时间差的毫秒数
  var leave1 = diff % (24 * 3600 * 1000)    //计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000))//计算出小时数
  //计算相差分钟数
  var leave2 = leave1 % (3600 * 1000)    //计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000))//计算相差分钟数
  //计算相差秒数
  var leave3 = leave2 % (60 * 1000)      //计算分钟数后剩余的毫秒数
  var seconds = Math.round(leave3 / 1000)
  return {
    hours,
    minutes,
    seconds
  }
}

Utils.isWeixin = () => {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}

const formatDecimals = (num) => {
  num = parseFloat(num)
  return num;
}
/**
 * 添加指定天数的日期
 * @param AddDayCount
 * @returns {number}
 */
Utils.getNowDay = (AddDayCount) => {
  var dd = new Date();
  dd.setDate(dd.getDate() + AddDayCount);//获取AddDayCount天后的日期
  var d = dd.getDate();
  return d;
}


Utils.getLastDay = () => {
  var date = new Date(), y = date.getFullYear(), m = date.getMonth();
  var lastDate = new Date(y, m + 1, 0);
  var lastDay = lastDate.getDate();
  return lastDay;
}

/**
 * 流量>=1024M时，转换为G
 * @param num
 * @returns 带单位的流量值
 */
Utils.changeFlow = (num) => {
  num = Math.abs(Number(num || 0));  //负数调整证书
  if (num >= 1024) {
    num = formatDecimals(Utils.accDiv(num, 1024)) + ' GB';
  } else {
    num = formatDecimals(num) + ' MB';
  }
  return num;
}
/**
 * 
 * @param num
 * @returns 带单位的流量值
 */
Utils.unitOfTime = (num) => {
  const seconds = Number(num || 0);
  // 计算小时
  const hours = 0;
  // 计算剩余的分钟
  const remainingMinutes = Math.floor(seconds / 60);
  // 计算剩余的秒数
  const remainingSeconds = 0;

  // 将结果格式化为字符串
  const formattedTime = `${hours ? `${hours}小时` : ''}${remainingMinutes ? `${remainingMinutes}分钟` : ''}${remainingSeconds && !hours ? `${remainingSeconds}秒` : ''}`;

  return formattedTime;
}
/**
 * 将base64转换为文件
 * @param {*} dataurl 
 * @param {*} filename 
 * @returns 
 */
Utils.dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

Utils.getUrlKey = (name) => {
  return (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) ||
    [, ""])[1] || null
}

/**
* 换算套餐名称中的数据流量单位
* @param sysName
* @returns {string}
*/
Utils.changePkgName = (sysName) => {
  if (!sysName) {
    return "";
  }
  //联通-1.5折-月套餐-300M/月-1个月有效
  //联通--池套餐-800M-1个月有效
  var nameArr = sysName.split("-");
  var newNameArr = nameArr;
  if (nameArr.length > 3 && sysName.indexOf("(") < 0) {
    for (var i = 0; i < nameArr.length; i++) {
      var nameObj = nameArr[i];
      if (nameObj && nameObj.indexOf("M") > -1 && nameObj.indexOf("MB") < 0) {
        var idx = nameObj.indexOf("M");
        var flowData = nameObj.substr(0, idx);
        nameObj = Utils.changeDataFlow(flowData) + nameObj.substr(idx + 1);
      }
      newNameArr[i] = nameObj;
    }
  }
  var newSysName = newNameArr.join('-');
  if (newSysName.indexOf("G") > -1 && newSysName.indexOf("GB") < 0 && sysName.indexOf("(") < 0) {
    newSysName = newSysName.replace('G', 'GB');
  }
  return newSysName;
}

/**
* 换算流量
* 大于1024M，换算成G
* @param flow
*/
Utils.changeDataFlow = (flow) => {
  flow = String(flow)
  if (flow.indexOf("达量") != -1) {
    flow = flow.replace(/[^0-9]/ig, "");
    if (flow >= 1024) {
      flow = parseFloat(parseFloat(Utils.accDiv(flow, 1024)).toFixed(2));
      return '达量' + flow + 'GB';
    } else {
      return '达量' + flow + 'MB';
    }
  } else {
    flow = parseFloat(flow);
    if (flow >= 1024) {
      flow = parseFloat(parseFloat(Utils.accDiv(flow, 1024)).toFixed(2));
      return flow + 'GB';
    } else {
      return flow + 'MB';
    }
  }
}
/**
* 时间戳转化
* 
* @param time
*/
Utils.formatTimestamp = (timestamp) => {
  if (!timestamp) {
    return "";
  }

  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
/**
* 卡名字转换
* 1移动 2 联通 3电信
* @param cardName
*/
Utils.cardChangeName = (val) => {
  val == 1 ? '移动' : val == 2 ? '联通' : val == 3 ? '电信' : ''
};
/**
* 处理认证数据
* 
* 
*/
Utils.attestationData = (data, operatorStr, iccid, callback) => {
  const public_address = process.env.VUE_APP_URL_BASE_API
  var code = data.code;
  var local = data.local;
  let priority = null;
  var verifyPhone = data.verifyPhone;
  authLocalGlobal = data.local;
  function isEmpty(value) {
    return value == null || value.length === 0;
  }
  if (code == 0) {
    Toast('该卡正在实名审核中, 请勿重复提交!')
  } else if (code == 1) {//查询成功
    if ((local == 2 || local == 3) && verifyPhone == 1) {
      window.location = public_address + "//wx/card/toSmsVerification?iccid=" + iccid + "&operator=" + operator + "&jumpOfficeFlag=1";
    } else {
      Toast('您的卡片已实名成功!')
      callback()
    }
  } else if (code == 3 || code == 5) {
    Toast('请订购套餐后再进行实名认证!')
  } else {
    var authType = data.authSubtype;
    var phoneUrl = "";
    if ((authLocalGlobal == 2 || authLocalGlobal == 3) && verifyPhoneGlobal == 1) {
      phoneUrl = public_address + "/wx/card/toSmsVerification?iccid=" + iccid + "&operator=" + operatorStr + "&jumpVerifiedUrl=";
    } else if (authLocalGlobal == 2 && verifyPhoneGlobal == 2 && (operatorStr == 2 || operatorStr == 3)) {
      if (operatorStr == 2) {
        //官方实名 且 不需要手机号验证
        window.location = data.url;
      } else if (operatorStr == 3) {
        window.location = data.url;
      }
      return true;
    }
    if (authType == 2) {
      //手机号验证
      window.location = phoneUrl + public_address + "/wx/jsScalePkg/toSmsVerification?iccid=" + iccid + "&operator=" + operatorStr + "&priority=" + priority;
    } else if (authType == 1) {
      /**********************************身份证验证start******************************************/
      if (local == 1 || local == 3) {
        //本地验证
        window.location = phoneUrl + public_address + "/wx/jsScalePkg/toVerifiesPage?iccid=" + iccid + "&iccidShow=" + iccid + "&priority=" + priority;
        return true;
      } else if (local == 2) {
        var flowCard = data.operator == 1 && data.cardFlag == 1
        var voiceCard = data.operator == 1 && data.cardFlag == 2
        if (flowCard || voiceCard) {
          if (!isEmpty(data.url)) {
            data.providerType === 'yd_iot_ec' ? window.location = phoneUrl + data.url : window.location = phoneUrl + public_address + "/h5/verified/toMbileVoiceIndex?iccid=" + iccid + "&iccidShow=" + iccid;
          } else {
            Toast(data.retMsg)
          }
        } else {
          //外部验证
          window.location = phoneUrl + data.url;
          return true;
        }
      }
      /**********************************身份证验证end******************************************/
    } else if (authType == 4) {
      if (local == 1 || local == 3) {
        //本地验证
        window.location = phoneUrl + public_address + "/wx/jsScalePkg/toVerifiesNoHandPage?iccid=" + iccid + "&iccidShow=" + iccid + "&priority=" + priority;
        return true;
      } else if (local == 2) {
        var flowCard = data.operator == 1 && data.cardFlag == 1
        var voiceCard = data.operator == 1 && data.cardFlag == 2
        if (flowCard || voiceCard) {
          if (!isEmpty(data.url)) {
            data.providerType === 'yd_iot_ec' ? window.location = phoneUrl + data.url : window.location = phoneUrl + public_address + "/h5/verified/toMbileVoiceIndex?iccid=" + iccid + "&iccidShow=" + iccid;
          } else {
            Toast(data.retMsg)
          }
        } else {
          //外部验证
          window.location = phoneUrl + data.url;
          return true;
        }
      }
    }
  }
};
export default Utils