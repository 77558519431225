import request, { get } from '@/utils/request'
import store from '../../store'
export function pkgGetFlowDetail(data) {
    return get(`/template/${store.state.cardType == 1 ? 'groupPkg' : 'pkg'}/getFlowDetail`, data);
}
export function toTemFlowPage(data) {
    return request({
        url: `/template/${store.state.cardType == 1 ? 'groupPkg' : 'pkg'}/toTemFlowPage`,
        method: 'get',
        params: data
    })
}
export function getPageAccountDetail(data) {
    return request({
        url: '/card/recharge/getContactMobile',
        method: 'post',
        data
    })
}
export function getPkgPackageDetailsList(data) {
    return request({
        url: `/template/${store.state.cardType == 1 ? 'groupPkg/pkg' : 'pkg'}/getPackageDetailsList`,
        method: 'post',
        params: data
    })
}
export function getPkgPackage(data) {
    return request({
        url: `/template/${store.state.cardType == 1 ? 'groupPkg' : 'pkg'}/getPackage`,
        method: 'post',
        data
    })
}
export function getPkgRechargeList(data) {
    return request({
        url: `/template/${store.state.cardType == 1 ? 'groupPkg' : 'pkg'}/getRechargeList`,
        method: 'post',
        params: data
    })
}
export function moneyCustPromotionPage(data) {
    return request({
        url: `/wx/account/moneyCustPromotionPage`,
        method: 'get',
        params: data
    })
}
export function getPkgPackages(data) {
    return request({
        url: `/template/${store.state.cardType == 1 ? 'groupPkg' : 'pkg'}/getPackages`,
        method: 'post',
        params: data
    })
}
export function prePay(data) {
    return request({
        url: '/td/card/prePay',
        method: 'post',
        params: data
    })
}