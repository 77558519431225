import { createStore } from 'vuex'
import mutations from './mutations.js'
import getters from './getters.js'
import actions from './actions.js'
import position from './modules/position.js'

export default createStore({
  state: {
    user: {},
    balanceMppType: 3, //充值公众号,3-客户公众号 5-中天公众号,默认1
    cardType: 1, //卡类型,1-语音 2-流量,默认1
    token: '',
    studentarr: [],
    headImage: '', //头像地址
    operational: {}, //运营数据
    student: {},
    requiredCertification: false, //企业账号 不需要认证
  },
  getters,
  mutations,
  actions,
  modules: {
    position
  }
})
