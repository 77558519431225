import axios from 'axios'
import { Notify } from 'vant';
import store from '@/store'
const WHITE_LIST = []

// create an axios instance
const service = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000 // 请求时长
})

// 请求拦截
service.interceptors.request.use(
  config => {
    // 发起请求前

    if (store.getters.token) {
      // 设置token
      config.headers['Authorization'] = store.getters.token
    }
    return config
  },
  error => {
    // 请求错误打印出来
    console.log(error)
    return Promise.reject(error)
  }
)

// 处理请求数据
service.interceptors.response.use(
  /**
   * response => response包含报头等请求信息，这里返回的数据是处理过后不含报头和状态码的
  */

  /**
   * 通过状态码判断数据返回类型
   */
  response => {
    const res = response.data

    const WHITE_FULL_PATH_LIST = WHITE_LIST.map(item => process.env.VUE_APP_BASE_API + item)
    // 排除code是否正常以及responseType是否为 blob ( maybe excel)
    if (res.code === 1 || res.ret === 0 || response.config.responseType === 'blob') {
      return Promise.resolve(res)
    } else if (res.code === 2) {
      Notify({
        message: '登录超时，请重新登录',
        type: 'warning',
        duration: 1000
      })
      // token过期 需要清空原来token
      store.dispatch('deleteuser').then(() => {
        location.reload()
      })
    } else {
      // 不需要弹出报错信息的接口视为白名单
      if (!WHITE_FULL_PATH_LIST.includes(response.config.url)) {
        Notify({
          message: res.msg || 'Error',
          type: 'warning',
          duration: 2000
        })
      }
      return Promise.reject(res.msg || 'Error')
    }
  },
  error => {
    if (!error.response || error.response.status === 500) {
      Notify({
        message: error.message || '服务器繁忙',
        type: 'warning',
        duration: 2000
      })
    }
    return Promise.reject(error)
  }
)

export function get(url, params) {
  return service({
    url,
    method: 'get',
    params
  })
}

export function post(url, data) {
  return service({
    url,
    method: 'post',
    data
  })
}

export default service
