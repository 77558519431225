import request from '@/utils/request'
export function getGroupPackage(data) {
    return request({
        url: '/wx/jsScaleGroup/getGroupPackage',
        method: 'get',
        params: data
    })
}

export function getGroupRechargeList(data) {
    return request({
        url: '/wx/jsScaleGroup/getRechargeList',
        method: 'POST',
        params: data
    })
}

export function getCurrAndHistoryList(data) {
    return request({
        url: '/wx/jsScalePkg/getCurrAndHistoryList',
        method: 'POST',
        params: data
    })
}
export function getGroupPackages(data) {
    return request({
        url: '/wx/jsScaleGroup/getGroupPackages',
        method: 'get',
        params: data
    })
}

export function diffVerifiedFlag(data) {
    return request({
        url: '/h5/verified/diffVerifiedFlag',
        method: 'get',
        params: data
    })
}
export function scaleToCardRefreshOrActivate(data) {
    return request({
        url: '/wx/scalePkg/toCardRefreshOrActivate',
        method: 'get',
        params: data
    })
}
export function getVoiceDetail(data) {
    return request({
        url: '/wx/jsScalePkg/getVoiceDetail',
        method: 'get',
        params: data
    })
}
