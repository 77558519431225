import request from '@/utils/request'

export function getHmcUser(data) {
  return request({
    url: '/user/v1/getParentInfo',
    method: 'post',
    data
  })
}

export function listStudent(data) {
  return request({
    url: '/user/v1/listStudent',
    method: 'post',
    data
  })
}
