
import { getHmcUser } from '../api/user.js'
import { getFlowDetail, toCardRefreshOrActivate, getWXuser } from '@/api/index/index.js';
import { pkgGetFlowDetail } from '@/api/index/kehu.js';
import { scaleToCardRefreshOrActivate, getVoiceDetail } from '@/api/index/yuyin.js';
import router from './../router'
import Utils from '@/utils/index';
import { Toast, Dialog } from 'vant';
const actions = {
  setuser({ commit }, value) {
    return getHmcUser({
      externalId: value
    }).then(res => {
      commit('SETUSER', res.data)
      commit('SETTOKEN', res.data.token)
      sessionStorage.setItem('user', JSON.stringify(res.data))
      sessionStorage.setItem('token', res.data.token)
    })
  },
  setHeadImage({ commit }) {
    let data = {
      wxuser: sessionStorage.getItem('wxuser'),
    };
    if (!data.wxuser) return
    return getWXuser(data).then(res => {
      commit('SETHEADIMAGE', res.data.headImageUrl)
      localStorage.setItem('headImage', res.data.headImageUrl)
    })
  },
  setAllType({ commit }) {  //设置全局卡类型 和 公众号类型
    commit('SETBALANCEMPPTYPE', sessionStorage.getItem('balanceMppType'))
    commit('SETCARDTYPE', sessionStorage.getItem('type'))
  },
  async setFlowInfo({ state, commit }) {
    let flowInfo = {};
    const onType = state.cardType //1是语音2流量
    const balanceMppType = state.balanceMppType //3是客户5中天
    function getInfo() {
      return new Promise((resolve) => {
        let iccid = sessionStorage.getItem('iccid');
        let data = {
          iccid: iccid,
          userId: sessionStorage.getItem('userId'),
          iccidShow: iccid,
          wxuser: sessionStorage.getItem('wxuser'),
        };
        const detail = balanceMppType == 3 ? pkgGetFlowDetail : onType == 1 ? getVoiceDetail : getFlowDetail
        detail(data).then((res) => {
          flowInfo = res.data;
          if (flowInfo.step == 4) {   //为4不允许进入
            return router.push({ path: '/home' })
          }
          if (flowInfo.lifeCycle == 5) {
            Dialog.alert({
              message: '您的卡片已失效，\n请联系卖家换卡后再进行该操作',
            })
          }
          if (!iccid) {
            iccid = flowInfo.iccid || flowInfo.iccidShow
            sessionStorage.setItem('iccid', iccid)
          }
          if (!flowInfo.iccid) flowInfo.iccid = iccid
          if (!sessionStorage.getItem('cardNumber')) sessionStorage.setItem('cardNumber', flowInfo.iccid)
          formatData(res.data);
          resolve();
        })
      });
    }
    function formatData(data) {
      let { usedFlow, surplusData, free, surplusVoice, usedVoice } = { ...data };
      // var surplusFlow = accDiv(data.surplusFlow,100);//剩余流量
      // var surplusData = accDiv(data.surplusData,100);//剩余流量(可用量 - 已用量 + 抵扣量)
      surplusData = onType == 1 ? surplusData / 100 : surplusData  //语音卡除以100
      usedFlow = onType == 1 ? usedFlow / 100 : usedFlow
      // let residueFlow = parseFloat(Utils.accAdd(surplusData, usedFlow));
      const formattedAmount = `${((free || 0) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      flowInfo.surplusDataUnit = Utils.changeFlow(surplusData || 0);
      flowInfo.residueFlow = surplusData;
      flowInfo.usedFlow = usedFlow
      flowInfo.usedFlowUnit = Utils.changeFlow(parseFloat(usedFlow || 0));
      flowInfo.voiceData = Utils.unitOfTime(surplusVoice || 0);
      flowInfo.usedVoiceFlow = Utils.unitOfTime(parseFloat(usedVoice || 0));
      flowInfo.free = formattedAmount;
      commit('SETOPERATIONAL', flowInfo);
      localStorage.setItem('operational', JSON.stringify(flowInfo))
    }
    await getInfo();
  },
  //刷新状态
  statusRefresh({ dispatch, state }) {
    const onType = state.cardType //1是语音2流量
    const iccid = sessionStorage.getItem('iccid');
    let datas = {
      iccid: iccid,
      operator: state.operational.operator,
      status: state.operational.status
    }
    const txt = state.operational.status == 1 ? '激活' : '刷新'
    Toast.loading({
      message: `状态${txt}中...`,
      forbidClick: true,
      loadingType: 'spinner',
    });
    // if (onType != 1) {
    //   datas.status = 2
    // }
    const Function = onType == 1 ? scaleToCardRefreshOrActivate : toCardRefreshOrActivate
    Function(datas).then(res => {
      if (res.data.code == 'ok') Toast.success(`状态已${txt}`);
      else Toast.fail(res.data.msg || `状态${txt}失败`);
      dispatch('setFlowInfo');
    }).catch(() => {
      Toast.fail(`状态${txt}失败`);
    });
  },
  deleteuser({ commit }) {
    return new Promise(() => {
      commit('SETUSER', {})
      commit('SETTOKEN', '')
      commit('SETSTUDENT', {})
      commit('SETHEADIMAGE', '')
      commit('SETOPERATIONAL', {})
      commit('SETSTUDENARR', [])
      sessionStorage.setItem('user', '')
      sessionStorage.setItem('token', '')
      sessionStorage.setItem('student', '')
      localStorage.setItem('headImage', '')
      localStorage.setItem('operational', '')
    })
  }
}

export default actions
