<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import wxApi from '@/utils/wechatPlugin';
// import { getWxConfig } from '@/api/home/index';
export default {
  name: 'app',
  components: {},
  methods: {
    // init(code) {
    //   getWxConfig({ code: code }).then((res) => {
    //     console.log(res.data);
    //     // window.location.href = res.data; 
    //     // this.$router.push({ path: res.data });
    //   });
    // },
    getUrlParam(name) {
      let url = window.location.href;
      // 获取 URL 参数部分
      let params = url.split('?')[1];
      // 将参数部分按照 & 分割成数组
      let paramsArray = params?.split('&');
      // 创建一个空对象来存储参数
      let paramsObj = {};
      // 遍历参数数组
      paramsArray?.forEach(function (param) {
        // 将参数按照 = 分割成键值对
        let keyValue = param.split('=');
        // 将键值对存储到对象中
        paramsObj[keyValue[0]] = keyValue[1];
      });
      return paramsObj[name];
    },
  },
  mounted() {
    let balanceMppType = this.getUrlParam('balanceMppType')||"";
    let wxuser = this.getUrlParam('wxuser')||""
    if(wxuser&&process.env.NODE_ENV==="development")sessionStorage.setItem('wxuser',wxuser);
    if(balanceMppType&&process.env.NODE_ENV==="development")sessionStorage.setItem('balanceMppType',balanceMppType);
    // this.init(code||urlCode);
    wxApi.initJssdk()
  },
};
</script>

<style></style>
