// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/balance',
    name: 'balance',
    meta: { title: '余额充值' },
    component: () => import('../views/balance/index.vue')
  },
  { //客户公众号
    path: '/idc/mp/:userId/packageRecharge',
    name: 'wxLogin',
    meta: { title: '加载中' },
    component: () => import('../views/login/yYIndex.vue')
  },
  {
    path: '/mp/new_template_pkg_inquire',
    name: 'newHome',
    meta: { title: '登录页' },
    component: () => import('../views/home.vue')
  },
  {
    path: '/mp/template_pkg_index',
    name: 'pkgIndex',
    meta: { title: '查询充值' },
    component: () => import('../views/index/index.vue')
  },
  {
    path: '/mp_group/template_group_index',
    name: 'pkgGroupIndex',
    meta: { title: '查询充值' },
    component: () => import('../views/index/index-yuyin.vue')
  },
  { //中天公众号
    path: '/wx/jsScalePkg/cardInquire',
    name: 'Login',
    meta: { title: '加载中' },
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/wx/jsScalePkg/toFlowIndexPage',
    name: 'ToFlowIndexPage',
    meta: { title: '登录页' },
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/home',
    name: 'Home',
    meta: { title: '登录页' },
    component: () => import('../views/home.vue')
  },
  {
    path: '/jsScalePkg/new_card_inquire_page',
    name: 'HomeA',
    meta: { title: '登录页' },
    component: () => import('../views/home.vue')
  },
  {
    path: '/jsScalePkg/new_style_index',
    name: 'Index',
    meta: { title: '查询充值' },
    component: () => import('../views/index/index.vue')
  },
  {
    path: '/jsScalePkg/new_style_group_index',
    name: 'groupIndex',
    meta: { title: '查询充值' },
    component: () => import('../views/index/index-yuyin.vue')
  },
  {
    path: '/packageInfo',
    name: 'packageInfo',
    meta: { title: '套餐详情' },
    component: () => import('../views/index/package-info.vue')
  },
  {
    path: '/record',
    name: 'Record',
    meta: { title: '余额变动细节' },
    component: () => import('../views/index/record.vue')
  },
  {
    path: '/orderRecord',
    name: 'OrderRecord',
    meta: { title: '订购记录' },
    component: () => import('../views/index/orderRecord.vue')
  },
  {
    path: '/coupon',
    name: 'Coupon',
    meta: { title: '优惠劵' },
    component: () => import('../views/index/coupon.vue')
  },
  {
    path: '/renewal',
    name: 'Renewal',
    meta: { title: '自动续费' },
    component: () => import('../views/index/renewal.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    meta: { title: '通讯录' },
    component: () => import('../views/index/contacts.vue')
  },
  {
    path: '/linkmanRecord',
    name: 'LinkmanRecord',
    meta: { title: '联系录操作记录' },
    component: () => import('../views/index/linkmanRecord.vue')
  },
  {
    path: '/linkmanDel',
    name: 'LinkmanDel',
    meta: { title: '删除联系人' },
    component: () => import('../views/index/linkmanDel.vue')
  },
  {
    path: '/topUp',
    name: 'TopUp',
    meta: { title: '余额充值' },
    component: () => import('../views/index/topUp.vue')
  },
  {
    path: '/pay',
    name: 'Pay',
    meta: { title: '支付' },
    props: true,
    component: () => import('../views/index/pay.vue')
  },
  {
    path: '/orderPackage',
    name: 'OrderPackage',
    meta: { title: '套餐订购' },
    component: () => import('../views/index/orderPackage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
})


export default router
