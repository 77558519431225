import request from '@/utils/request'
import store from '../../store'

export function getStudentLocation(data) {
  return request({
    url: '/student/healthyData/v1.0/getStudentLocation',
    method: 'get',
    params: data
  })
}

export function getWxConfig(data) {
  return request({
    url: '/wx/jsScalePkg/cardInquire',
    method: 'get',
    params: data
  })
}

export function getJsapi(data) {
  return request({
    url: '/jsapi',
    method: 'get',
    params: data
  })
}

export function getShare(data) {
  return request({
    url: '/student/healthyData/v1.0/getStudentLocation',
    method: 'get',
    params: data
  })
}
export function getIccidsByOpenId(data) {
  const mpUrl = store.state.balanceMppType == 3 ? '/template/pkg' : '/a/recharge'
  return request({
    url: mpUrl + '/getIccidsByOpenId',
    method: 'get',
    params: data
  })
}
export function toFlowIndexPage(data) {
  return request({
    url: '/wx/jsScalePkg/toFlowIndexPage',
    method: 'get',
    params: data
  })
}
export function checkAndTransformIccidByShare(data) {
  return request({
    url: '/webapp/card/checkAndTransformIccidByShare',
    method: 'post',
    params: data
  })
}
