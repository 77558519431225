import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './utils/index'
// import axios from './static/js/axios';
import VeLine from 'v-charts/lib/line.common'
import VeRing from 'v-charts/lib/ring.common'
import VeHistogram from 'v-charts/lib/histogram.common'
import 'v-charts/lib/style.css'

import 'normalize.css/normalize.css'
import 'lib-flexible/flexible'
// import './utils/mapinit.js'
import 'vant/es/dialog/style';
import './utils/index.js'
import './utils/mixins.js'
import './utils/common.js'
import './assets/css/vant.scss'
import WechatPlugin from '@/utils/wechatPlugin'
// 从本地存储中获取数据
const savedOperationalData = JSON.parse(localStorage.getItem('operational'));
const savedHeadImageData = localStorage.getItem('headImage');
const savedType = sessionStorage.getItem('type');
const savedBalanceMppType = sessionStorage.getItem('balanceMppType');
const savedEnterpriseType = sessionStorage.getItem('enterpriseType');

if (savedOperationalData) {
  store.commit('SETOPERATIONAL', savedOperationalData);
}
if (savedHeadImageData) {
  store.commit('SETHEADIMAGE', savedHeadImageData);
}
if (savedType) {
  store.commit('SETCARDTYPE', savedType);
}
if (savedBalanceMppType) {
  store.commit('SETBALANCEMPPTYPE', savedBalanceMppType);
}
if (savedEnterpriseType) {
  store.commit('SETREQUIREDCERTIFICATION', savedEnterpriseType == 1 || savedEnterpriseType == 3);
}
import { Button, Icon, Image as VanImage, NavBar, Dialog, PullRefresh, Checkbox, Sticky, List, CheckboxGroup, RadioGroup, Radio, Toast, Switch, Tab, Tabs, Cell, DatetimePicker, Empty, Form, CellGroup, Steps, Step, Circle, Popup, Picker, Calendar, Field } from 'vant';

const app = createApp(App);
app.use(WechatPlugin)
import dayjs from 'dayjs'
app.config.globalProperties.$dayjs = dayjs

app.use(store).use(router)
app.use(utils)
app.component(VeLine.name, VeLine)
app.component(VeRing.name, VeRing)
app.component(VeHistogram.name, VeHistogram)

app
  .use(Button)
  .use(Icon)
  .use(VanImage)
  .use(Checkbox)
  .use(RadioGroup)
  .use(List)
  .use(Sticky)
  .use(Radio)
  .use(NavBar)
  .use(Switch)
  .use(PullRefresh)
  .use(Cell)
  .use(Dialog)
  .use(CellGroup)
  .use(CheckboxGroup)
  .use(Form)
  .use(Empty)
  .use(Tab)
  .use(Tabs)
  .use(DatetimePicker)
  .use(Steps)
  .use(Step)
  .use(Circle)
  .use(Popup)
  .use(Picker)
  .use(Calendar)
  .use(Toast)
  .use(Field)

app.mount('#app')