const position = {
  state: {
    adress: '深圳市南山区'
  },
  mutations: {
    SETADRESS(state, adress) {
      state.adress = adress
    }
  },
  actions: {
    setadress({ commit }, value) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, 2000)
      }).then(() => {
        console.log(value);
        commit('SETADRESS', value.name)
      })
    }
  }
}
export default position